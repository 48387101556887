import * as React from 'react'

import type { HeadFC } from 'gatsby'

import SEO from '@/components/Base/SEO'
import Layout from '@/components/Layout'
import NotFound from '@/components/NotFound'

const NotFoundPage = () => {
  return (
    <Layout>
      <NotFound />
    </Layout>
  )
}

export default NotFoundPage

export const Head: HeadFC = () => <SEO title='ページが見つかりませんでした' />
