import { navigate } from 'gatsby'
import * as React from 'react'

import * as styles from '@/components/NotFound/NotFound.module.scss'

const NotFound = () => {
  const [time, setTime] = React.useState<number>(5)

  React.useEffect(() => {
    const countDownInterval = setInterval(() => {
      if (time <= 0) {
        clearInterval(countDownInterval)
        navigate('/', { replace: true })
      } else {
        setTime((prevTime) => prevTime - 1)
      }
    }, 1000)

    return () => {
      clearInterval(countDownInterval)
    }
  }, [time])

  return (
    <main className={styles['notFound']}>
      <h1 className={styles['title']}>404</h1>
      <p className={styles['body']}>
        ページが見つかりませんでした。
        <br />
        {`${time.toLocaleString('ja-JP', {
          numberingSystem: 'fullwide',
          // related: https://github.com/microsoft/TypeScript/issues/52072
        } as Intl.NumberFormatOptions)}秒後にトップページに移動します。`}
      </p>
    </main>
  )
}

export default NotFound
